<template>
  <div class="outer">
    <div class="hl-header">
      <div class="hl-search">
        <div class="hl-title">取电卡管理</div>
      </div>
    </div>
    <div class="searchAll">
      <el-card>
        <!-- 一个表单 -->
        <el-form :inline="true" :model="condition">
          <el-form-item label="卡号" prop="卡号">
            <el-input
              placeholder="请输入卡号"
              v-model="condition.cardNo"
              style="width: 100%"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="addIdType2"
              type="primary"
              @click="getFounderList()"
              >查询</el-button
            >
            <el-button class="addIdType3" type="primary" @click="reSearch()"
              >重置</el-button
            >
          </el-form-item>
          <el-button
            v-if="chuangjian"
            type="primary"
            @click="addIdType(0, null)"
            class="addIdType"
            >+ 取电卡管理</el-button
          >
        </el-form>
      </el-card>
    </div>

    <div class="hl-content">
      <!-- <div class="mokuai">取电卡管理列表</div> -->
      <el-table
        class="hl-table"
        :data="tableData"
        height="90%"
        :header-cell-style="{ background: '#F4F5F9', color: '#000000' }"
      >
        <el-table-column label="卡号">
          <template slot-scope="scope">
            <span>{{ scope.row.cardNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="权限编码">
          <template slot-scope="scope">
            <span>{{ scope.row.cardType }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <div class="cz">
              <div class="qidong">
                <el-button
                  v-if="xiugai"
                  type="text"
                  @click="edit(scope.$index, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  style="color: red"
                  type="text"
                  @click="del(scope.row)"
                  >删除</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @size-change="onPaginationSize"
          @current-change="onPaginationIndex"
          :current-page="condition.offset"
          :page-size="condition.limit"
          layout=" prev, pager, next, jumper,total"
          :total="totolNums"
        ></el-pagination>
      </div>
    </div>
    <!-- 删除 -->
    <el-dialog title="提示" :visible.sync="deleteVisible" width="30%">
      <el-input
        placeholder="请输入删除指示"
        v-model="todelete"
        style="width: 100%"
        maxlength="10"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
    </el-dialog>
    <addModify ref="addModify" />
    <!-- <disableModify ref="disableModify" /> -->
  </div>
</template>
<script>
// import { querySelectAllUser } from "@/api/api2.js";
import { getRequest, postRequest, deleteRequest } from "@/api/api.js";
import addModify from "@/views/powerCardMange/addModify.vue";
// import disableModify from "@/views/roomState/disableModify.vue";
export default {
  data() {
    return {
      tableData: [],
      condition: {
        cardNo: null,
        limit: 10,
        offset: 1,
      },
      totolNums: null,
      chuangjian: false,
      xiugai: false,
      shanchu: false,
      deleteVisible: false,
      row: {},
      todelete: "",
    };
  },
  components: {
    addModify,
    // disableModify
  },
  created() {
    if (!this.power.checkedpower("0401004")) {
      // 在PMS管理系统里面-获取权限
      this.$router.push("power");
    }
    // 创建
    this.chuangjian = this.power.checkedpower("0401001");
    // 修改
    this.xiugai = this.power.checkedpower("0401002");
    // 删除，禁用
    this.shanchu = this.power.checkedpower("0401003");
    this.getFounderList();
  },
  methods: {
    reSearch() {
      this.condition = { cardNo: null, limit: 10, offset: 1 };
      this.getFounderList();
    },
    // 获取创始人列表
    getFounderList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      getRequest("selectAllSysCard", this.condition).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.tableData = res.data.list;
          this.totolNums = res.data.total;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 删除
    del(row) {
      this.deleteVisible = true;
      this.todelete = "";
      this.row = row;
    },
    deleteSure() {
      if (this.todelete != "yes") {
        this.$message({
          duration: 5000,
          message: "请输入指示！",
          center: true,
        });
        return;
      }
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        id: this.row.id,
      };
      deleteRequest("deleteSysCard?id=" + this.row.id).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "删除成功!",
            type: "success",
          });
          this.deleteVisible = false;
          this.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 禁用
    toDisable(index, row) {
      let state = "禁用";
      this.$refs.disableModify.show(state, row, index);
    },
    search() {},
    // 新增
    addIdType() {
      let state = "创建";
      this.$refs.addModify.show(state);
    },
    // 修改
    edit(index, row) {
      let state = "编辑";
      this.$refs.addModify.show(state, row, index);
    },
    // 每页的数据数
    onPaginationSize(Sizeval) {
      this.condition.limit = Sizeval;
      this.getFounderList();
    },
    // 页数
    onPaginationIndex(Indexpage) {
      this.condition.offset = Indexpage;
      this.getFounderList();
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.outer {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button {
  width: 60px;
  height: 30px;
  /* background:rgb(163, 197, 248) ; */
  background-color: cornflowerblue;
  color: white;
  border: none;
}
.button2 {
  background-color: white;
  border: 1px solid #999;
  color: #666;
}
.hl-header {
  width: 100%;
  height: 60px;
  display: flex;
}
.hl-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  position: relative;
}
.hl-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
}
.el-card {
  position: relative;
}
.addIdType {
  width: 136px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  position: absolute;
  right: 20px;
}
.hl-search .span {
  width: 40px;
  height: 30px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 14px;
  border: none;
}
.hl-content {
  flex: 1;
  background-color: #fff;
  padding: 21px 32px;
  position: relative;
  overflow: scroll;
}
.mokuai {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.85;
  margin-bottom: 21px;
}
.hl-table {
  /* background: red; */
  overflow: auto;
}
.cz-button {
  width: 74px;
  height: 28px;
  background: #6ec543;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
}
.qidong {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
}
.line {
  display: inline-block;
  height: 14px;
  border: 1px solid #e8ecef;
}
.cz {
  display: flex;
}
.el-table {
  height: 90%;
  overflow: hidden;
}
.block {
  position: absolute;
  bottom: 10px;
  right: 32px;
}
.qiyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #387dff;
  border-radius: 50%;
  margin-right: 6px;
}
.jinyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 50%;
  margin-right: 6px;
}
.zt > div {
  display: flex;
  align-items: center;
}
.addIdType2 {
  width: 100px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.dialog-footer > .el-button {
  width: 60px;
  height: 30px;
}
.addIdType3 {
  width: 80px;
  height: 40px;
  color: #fff;
  background: rgb(151, 151, 151);
  border: rgb(151, 151, 151);
}
.searchAll {
  margin-bottom: 20px;
}
</style>