<template>
  <div>
    <el-dialog
      :title="title + '取电卡管理'"
      :visible.sync="showing"
      width="550px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <el-form-item label="卡号" prop="cardNo" style="width: 100%">
            <el-input
              placeholder="请输入卡号"
              v-model="formInline.cardNo"
              style="width: 100%"
            ></el-input>
          </el-form-item>
          <el-form-item label="权限编码" prop="cardType">
            <el-select
              class="text"
              style="width: 100%"
              v-model="formInline.cardType"
              placeholder="请选择权限编码"
            >
              <el-option
                v-for="item in cardList"
                :key="item.code"
                :label="item.code"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="createStorage('formInline')"
              >确定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { createInsertUser, updateInsertUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";

export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      index: null,
      //表单
      formInline: {},
      cardList: [],
      //表单验证
      rules: {
        cardNo: [
          {
            required: true,
            message: "请输入卡号",
            trigger: "blur",
          },
        ],
        cardType: [
          {
            required: true,
            message: "请输入权限编码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
    },
    show(item, val, index) {
      this.formInline = {};
      this.getCardList();
      this.title = item;
      this.showing = true;
      if (item == "编辑") {
        this.bedisabled = true;
        this.formInline = JSON.parse(JSON.stringify(val));
      }
    },
    getCardList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      getRequest("selectAllSysCardPower", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.cardList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    createStorage(formData) {
      // 表单验证-----------
      // 新增
      this.$refs[formData].validate((valid) => {
        if (valid) {
          if (this.title == "创建") {
            postRequest("insertSysCard", this.formInline).then((res) => {
              if (res.status == 200) {
                this.$message({
            duration: 5000,
                  message: "创建成功!",
                  type: "success",
                });
                this.hide();
                this.$refs.formInline.resetFields(); //清空表单
                this.$parent.getFounderList();
              } else {
                this.$message({
            duration: 5000,
                  message: res.message,
                  type: "warning",
                });
              }
            });
          } else {
            this.updateStation(); //调用编辑函数
          }
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
    // 编辑
    updateStation() {
      postRequest("updateSysCard", this.formInline).then((res) => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "编辑成功!",
            type: "success",
          });
          this.hide();
          this.$refs.formInline.resetFields(); //清空表单
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.el-button {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
</style>